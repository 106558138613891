import React from 'react'
import { Box } from 'theme-ui'
import Video from './Video'

export default ({ src, height, ...props }) => 
<Box sx={{
  position: "relative",
  backgroundImage: "url('../../iphone6_chrome.png')",
  backgroundSize: "100%",
  width: "100vw",
  '--height': height,
  maxWidth: `calc(var(--height) * 0.5118025751)`,
  maxHeight: 'var(--height)'}}
  {...props}
>
  <Box sx={{ position: "relative", pb: "209.08045977%"}} >
    <Video src={src} sx={{position: "absolute", px: "10.55%", pt: "27.5%", width: "100%"}}/>
  </Box>
</Box>