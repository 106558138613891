import React from 'react'
import { Box, Text, Flex, Grid, Heading, Styled } from 'theme-ui'
import Layout from "../components/layout"
import Image from "../components/Image"
import Toggle from "../components/Toggle"
import Slideshow from "../components/Slideshow"
import Iphone6 from "../components/Iphone6"
import SEO from "../components/seo"

export default () => 
  <Layout>
    <SEO keywords={['design', 'product designer', 'interface designer']} title='Murphy’s English Grammar in Use Case Study' description="Reimagining the app of the world’s best-selling grammar book for intermediate learners of English" project="murphy"/>

    <Heading as="h1" variant="text.h1" sx={{mb: 6}}>Murphy’s English Grammar in Use</Heading>
    <Box sx={{maxWidth: '50rem', mb: 8}}>
      <Text variant="h4">Reimagining the app of the world’s best-selling grammar book for intermediate learners of English.</Text>
    </Box>

    <Box variant='boxes.gaplessImage' sx={{mb: 7}}>
      <Image src="murphy/murphy_hero.png" alt='Hero image' />
    </Box>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="①" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Research and problem definition
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 4, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
            Some time ago I decided to improve my English, and the Raymond Murphy’s “English Grammar in Use” app became a thing I started using on a daily basis. Unfortunately, whilst it is a brilliant self-study reference and practice book, the app itself has a really weak UI. I tried to redesign it and fix its current problems.
          </Text>
        </Box>
        <Box sx={{mt: 5, mb: 8, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
            <Box>
              <Text as="p">I asked my tutor to let me interview her students to find out how they use the app. They, both new and experienced users of the app, were given several tasks to complete using the current app, such as:</Text>
              <Styled.ul>
                <Styled.li>Find a particular unit by its name</Styled.li>
                <Styled.li>Find a particular unit by its reference in the book</Styled.li>
                <Styled.li>Find all the unfinished exercises of the unit</Styled.li>
                <Styled.li>Tell what exercises of the unit haven't been finished</Styled.li>
              </Styled.ul>
            </Box>
            <Text as="p">
              After conducting these interviews, I listed all the pain points of each user and grouped them into certain tasks. Interestingly, even the experienced users faced issues, e.g. some of them didn’t even realise that the app has search functionality (which is hidden under a hamburger).
            </Text>
          </Grid>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="②" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Ideation and wireframing
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, mb: 8, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
            <Box>
              <Text as="p">
                Once the pain points were defined, I had a rough idea of what a new structure might look like. I wanted the UI to not only have a modern feel, but also to follow the iOS design language. Since the app’s navigation wasn’t too complicated, moving away from a hamburger menu to a tab bar seemed like a no-brainer to me.
              </Text>
              <Text as="p">
                To solve the issue around finding a particular unit, the UI of the “All units” screen now displays the unit numbers while collapsed. Meanwhile, the last opened group is expanded by default.
              </Text>
              <Text as="p">
                To help users find unfinished exercises of a particular unit, I visualised the current flow of choosing a unit to study defining “Complete the exercise” as the end point. This helped me identify the features necessary to take the edge off the pain point. Well, almost*.
              </Text>
              <Text as="p" sx={{mt: 4, textIndent: 'unset', fontSize: '11px', fontFamily: 'monospace'}}>
                * — I designed the proposed flow and created rough sketches of all screens of the app. These were loaded using InVision and the resulting prototype provided to the students. Although, observing how they used the prototype showed me I was totally wrong. People don’t need such detailed information about a unit to keep track of exercises and unit completion easily. The only thing they want to know is whether the unit is completed or not, so I brought colour coding into play and got rid of the detailed information screen.
              </Text>
            </Box>
            <Box>
              <Text as="p">
                In addition to fixing the core functionality issues, I added a bunch of new features such as:
              </Text>
              <Styled.ol>
                <Styled.li><b>Recaps</b>: a new section that refreshes your memory. The app monitors what (and when) you complete units, tracking how many mistakes you make to help choose further units.</Styled.li>
                <Styled.li><b>Goals and Statistics</b> because who doesn't love to watch their progress, right?</Styled.li>
                <Styled.li><b>User Registration</b> to sync user progress across all their devices. It’s worth noting that this particular feature got mixed feedback, so I decided to exclude it from the final prototype. Instead, it was replaced with a transparent iCloud sync. Less is more!</Styled.li>
              </Styled.ol>
            </Box>
          </Grid>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Box sx={{my: 4, mx: [-4, -6, -8]}}>
      <Text sx={{variant: 'text.h2', pl: [4, 6, 8], mb: 0}}>③ Translating prototype into UI</Text>

      <Box sx={{mt: 4, ml: [4, 6, 8], pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
        <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 8, color: "muted"}}>
          Once all feedback was collected, I started working on the UI. Initially, I used the default iOS components, but then I decided to try something different and went with a custom card-based interface.
        </Text>
      </Box>

      <Box sx={{mb: 8}}>
        <Text variant="h3" as="h3" sx={{maxWidth: "800px", mb: 6, ml: [4, 6, 8], pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          All Units
        </Text>
        <Box sx={{bg: "subtleBg", borderRadius: 3, py: 7, mt: 4, mx: [4, 6, 8]}}>
          <Image src="murphy/units_.png" alt='The All Units screen' height="40vw" sx={{mx: "auto"}}/>
        </Box>
        <Text as="p" sx={{maxWidth: "800px", px: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          The “All units” screen, in particular, received a lot of improvements. Collapsed groups of units — topics — now have a little progress bar and indexes of contained units, which is great in helping the user understand where they are (and match units in the app with the paper book’s ToC.)
        </Text>
      </Box>

      <Box sx={{mb: 8}}>
        <Text variant="h3" as="h3" sx={{maxWidth: "800px", mb: 6, ml: [4, 6, 8], pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          Unit
        </Text>

        <Slideshow altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{variant: "boxes.slideWrapper"}} altText="Much like in the original app, each unit screen has two parts: explanations and exercises. Explanations’ design was simplified, and now it’s presented as a stack of cards — one card per rule. The typography and readability were improved.">
            <Flex sx={{variant: "boxes.slideL"}}>
              <Image src="murphy/unit_.png" alt='Some of the unit screens' height="40vw" />
            </Flex>
          </Box>

          <Box sx={{pl: [4, 6, 8], outline: 'none'}} altText="The layout of exercises was also simplified. First of all, I removed the in-app custom keyboard (that was the most annoying thing in the app) and returned the system-wide one. If you have to enter two (or more) answers, the arrow keys above an input control (a keyboard, or a picker) appear to help you move focus between all the input fields.">
            <Flex sx={{variant: "boxes.slideL"}}>
              <Image src="murphy/exercise.png" alt='Some of the exercise screens.' height="40vw" />
            </Flex>
          </Box>

          <Box sx={{pl: [4, 6, 8], outline: 'none'}} altText="Dragging words in a put-the-word-in-the-right-order exercise. Expanding the word while dragging allows the user to actually see what’s happening.">
            <Flex sx={{variant: "boxes.slideL"}}>
              <Iphone6 src="murphy/dragging.mp4" height="40vw" />
            </Flex>
          </Box>

          <Box sx={{pl: [4, 6, 8], outline: 'none'}} altText="Typing ">
            <Flex sx={{variant: "boxes.slideL"}}>
              <Iphone6 src="murphy/typing.mp4" height="40vw" />
            </Flex>
          </Box>

          <Box sx={{pl: [4, 6, 8], outline: 'none'}} altText="Choosing a specific exercise of the unit. The menu utilises colour coding to help students see their progress at a glance.">
            <Flex sx={{variant: "boxes.slideL"}}>
              <Iphone6 src="murphy/collapse.mp4" height="40vw" />
            </Flex>
          </Box>
        </Slideshow>
      </Box>

      <Box sx={{mb: 8}}>
        <Text variant="h3" as="h3" sx={{maxWidth: "800px", mb: 6, ml: [4, 6, 8], pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          Search
        </Text>
        <Box sx={{bg: "subtleBg", borderRadius: 3, py: 7, mt: 4, mx: [4, 6, 8]}}>
          <Image src="murphy/search.png" alt='Search screen' height="40vw" sx={{mx: "auto"}}/>
        </Box>
        <Text as="p" sx={{maxWidth: "800px", px: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
        The new search screen is also accessible from the tab bar. Now it’s much easier to differentiate items by their type: units are highlighted by their number, and glossary items are labelled with an <svg style={{marginBottom: "-.35em"}} role="img" aria-label="A-Z" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 13" width="2.3em" height="1.3em"><rect width="23" height="13" fill="#FAD119" rx="1.3"/><path fill="#06100D" fillRule="nonzero" d="M6.0801 9.1l.2106-.663h1.9422l.2106.663h1.3728L7.8429 3.64H6.6807l-1.95 5.46h1.3494zm1.8408-1.6536h-1.326L7.2657 5.2l.6552 2.2464zm5.538-.1872v-.9516h-3.2994v.9516h3.2994zM18.4977 9.1V8.0392h-2.5896L18.5835 3.64h-4.4616v1.0608h2.4492L13.9035 9.1h4.5942z"/>
</svg> icon.
        </Text>
      </Box>

      <Box sx={{mb: 8}}>
        <Text variant="h3" as="h3" sx={{maxWidth: "800px", mb: 6, ml: [4, 6, 8], pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          Profile
        </Text>
        
        <Slideshow altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          <Box sx={{pl: [4, 6, 8], outline: 'none'}} altText="Transitions in Profile.">
            <Box sx={{bg: "subtleBg", borderRadius: 3, p: 7}}>
              <Iphone6 src="murphy/profile.mp4" height="40vw" />
            </Box>
          </Box>

          <Box sx={{pl: [4, 6, 8], outline: 'none'}} altText="This new Profile screen combines all the menu items (e.g. Study guide, Glossary, Settings) hidden under the original app’s hamburger menu with a new Goals and Statistics widget and a link to Recap learnt units.">
            <Flex sx={{bg: "subtleBg", position: "relative", borderRadius: 3, p: 7}}>
              <Image src="murphy/profile.png" alt='Some of the unit screens' height="40vw" />
            </Flex>
          </Box>
        </Slideshow>
      </Box>
    </Box>

    <Box sx={{my: 4}}>
      <Text sx={{variant: 'text.h2', mb: 0}}>④ What I’ve learnt</Text>
      <Box sx={{mt: 5, mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
        <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
          <Box as="ul" sx={{variant: 'styles.ul', mt: 0}}>
            <Styled.li><b>This project made me appreciate the importance of interviewing</b>. Without having any available analytics of usage the original app, I got a lot of insights just by asking people open questions and observing their behaviour. This helped me a lot to start working on the redesign being not related to the software developers.</Styled.li>
            <Styled.li><b>I’ve learnt how to plan my time better</b>. I set a goal to complete the redesign in a month of spare time. This strict deadline pushed me to create a roadmap with the time limits for each task from conducting interviews to creating UI and follow it closely.</Styled.li>
            <Styled.li><b>I’ve understood which tools are great for me</b>. I‘ve been using a lot of prototyping tools while working on the project. I tried Flinto, Framer Studio, Principle, and InVision. Each tool has its own pros and cons, and I’ve become more adept at testing concepts using the proper prototyping tool and at the proper fidelity.</Styled.li>
          </Box>
          <Box>
            <Text as="p">
            To test the UX part, you’d better use InVision, which is great for low-fidelity prototyping. To create micro-interactions with no logic, Principle and Flinto are the right choices. Otherwise, Framer Studio is the only option.
            </Text>
            <Text as="p">
            I tried to build the entire app using Framer, but it was a time-consuming and meaningless process. Although, I found it handier and more powerful than the others, and I picked it up dramatically. It has endless possibilities so I felt in love with it. <span role="img" aria-label="Thanks for reading!">❤️</span>
            </Text>
          </Box>
        </Grid>
      </Box>
    </Box>
  </Layout>