/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Video = ({ src, sx }, props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: { eq: "mp4" }}) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `)

  const match = useMemo(() => (
    data.allFile.edges.find(({ node }) => src === node.relativePath)
  ), [ data, src ])

  return (
    <video loop autoPlay muted sx={sx} {...props}>
      <source src={match.node.publicURL} type="video/mp4" />
    </video>
  )
}

export default Video